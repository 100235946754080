const confirmationDialogState = {
  open: (content) => {},
  onConfirm: () => {},
  onCancel: () => {},
}

const ConfirmationDialogCommand = {
  // Commands for Dialog
  openCallback: (func) => (confirmationDialogState.open = func),
  onCancel: () => confirmationDialogState.onCancel(),
  onConfirm: () => confirmationDialogState.onConfirm(),

  // Commands for components
  open: (content, onConfirm, onCancel) => {
    if (onCancel) {
      confirmationDialogState.onCancel = onCancel
    }
    confirmationDialogState.onConfirm = onConfirm
    confirmationDialogState.open(content)
  },
}

// export type ConfirmationDialogContent = {
//   title: string
//   contentText: string
//   confirmationText?: string
// }

export default ConfirmationDialogCommand
