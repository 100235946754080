import React from 'react';
import { render } from 'react-dom';
import './index.css';
import JourneyPage from './main/index'
import { EDSApplication } from '@ellucian/react-design-system/core'

const root = document.getElementById('root');
render(
  <React.StrictMode>
    <EDSApplication>
      <JourneyPage pageInfo={{ basePath: '/' }} userInfo={{ tenantId: process.env.REACT_APP_JOURNEY_TENANT_ID, locale: process.env.REACT_APP_JOURNEY_LANG }} />
    </EDSApplication>
  </React.StrictMode>, root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
