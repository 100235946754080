import { feathers } from '@feathersjs/feathers'
import { MemoryService } from '@feathersjs/memory'

export const localMemory = feathers()
const ops = {
  methods: ['create', 'get', 'update']
}

// Note: Permission matrix implemented in api in two services:
//  matrix by rows --> accessControlList(service) = array of pairs ['role_name', 'RWX] for that particular service
//  matrix by columns --> objectAuthorizationList(role) = array of pairs ['view_name', 'RWX'] for that particular role

// Note we have just one record per service, will be always id 0 (since it is the first one)
// How to use:
//    read
//        import { userContext } from 'localMemory' 
//        const theUserContext = await userContext.get(0)
//        const myVar = theUserContext.user.userName
//    modify
//        import { userContext } from 'localMemory' 
//        let theUserContext = await userContext.get(0)
//        theUserContext.loggedIn = new_value
//        userContext.update(0, theUserContext)
//    detect changes
//        import { userContext } from 'localMemory' 
//        userContext.on('updated', (theUserContext) => {
//            console.log("do something with", theUserContext)
//        })

// If you want to add more services, remember that each service should represent a topic with a single state, 
//    for instance, do not put together tenantId and user since they can change independently
//    remember the idea is to use a simple ".on('updated', do_something)", not ".on('update', check which one was change before do something)"

// REMEMBER: 
//    all these entries (the object localMemory) disappear when refreshing the page (F5) or when re-writing the URL manually (basically at any close, navigate, or reload page)
//    although they will remain as long as useNavigate() is used (since React Router keeps sync wiht Browser history)

// REMEMBER: when used in .jsx, that is, inside a React component, be careful the listener is not created many times
// The pattern to follow is this (example using userContext memory):
    // import { userContext } from 'localMemory' 
    // useEffect(() => {
    //   const effect = (theUserContext) => {
    //     // put your stuff here, for example
    //     setIsAuthenticated(theUserContext.loggedIn)
    //   }
    //   userContext.get(0).then(effect)    // to get the current value at the moment the component mounts
    //   userContext.on('updated', effect)  // to get the future values if they change after the component mounted
    //   return () => userContext.removeListener('updated', effect) // to remove duplicated listeners after several mounting/unmounting
    // }, [])
// This pattern can be used in any view/component


// Tenant context
localMemory.use('tenant-context', new MemoryService(ops))
const tenantContext = localMemory.service('tenant-context')
tenantContext.create({
  tenantId: null,
  institutionName: null
})
export { tenantContext }

// User context
localMemory.use('user-context', new MemoryService(ops))
const userContext = localMemory.service('user-context')
userContext.create({
  loggedIn: undefined, // better undefined than false, so index.js will not show for a second the login box while still getting the user token for first time
  user: null, // the same object as user in db. TODO: in db user.role is still one single string, not an array
  userPaymentTransactions: [],
  accessToken: null
})
export { userContext }

// Permission context
localMemory.use('permission-context', new MemoryService(ops))
const permissionContext = localMemory.service('permission-context')
permissionContext.create({
  role: '', // the single role in user object, TO BE CHANGED in user in DB
  roles: [], // list below is the permision combination for ALL these roles 
  objectAuthorizationList: [], // array of pairs ['view_name', 'RWX']
})
export { permissionContext }

// PendingJobs
localMemory.use('pending-jobs', new MemoryService(ops))
const pendingJobs = localMemory.service('pending-jobs')
pendingJobs.create({
  // pendingJobs = [ {view/oper, JobId} , {} ] , who is the job owner? view or user?
  pendingJobs: []
})
export { pendingJobs }

// Session profile
// last tab open, last course visited, last search done, colors, configurable options, etc...
localMemory.use('session-profile', new MemoryService(ops))
const sessionProfile = localMemory.service('session-profile')
sessionProfile.create({
  // lastTabOpen: null, // example of other stuff not kept in db
  userProperties: {}, // the record 'user-properties' in DB. For now it contains just 'lastCareerPathSearches'
})
export { sessionProfile }
