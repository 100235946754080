import React from 'react'
import { injectIntl, IntlProvider } from 'react-intl'
import PropTypes from 'prop-types'
import messages from '../../i18n'

export function withIntl(WrappedComponent) {
  const WithIntl = (props) => {
    const {
      userInfo: { locale },
    } = props
    const InjectedComponent = injectIntl(WrappedComponent)

    return (
      // eslint-disable-next-line dot-notation
      <IntlProvider locale={locale} messages={messages['en']}>
        <InjectedComponent {...props} />
      </IntlProvider>
    )
  }

  WithIntl.propTypes = {
    userInfo: PropTypes.object,
  }
  WithIntl.displayName = `WithIntl(${WrappedComponent?.displayName})`

  return WithIntl
}
