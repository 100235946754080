import React, { useState } from 'react'
import { withStyles } from '@ellucian/react-design-system/core/styles'
import { componentId, elementId } from '../../utilities/componentHelper'
import { Alert, INLINE_VARIANT, PAGE_VARIANT } from '@ellucian/react-design-system/core'
import AlertCommand from '../commands/AlertCommand'
import PropTypes from 'prop-types'

const mfeId = componentId()

const styles = {
  page: {
    top: '0 !important',
  },
}

const AlertMessage = (props) => {
  const { classes } = props
  const [isOpen, setIsOpen] = useState(false)
  const [text, setText] = useState('')
  const [type, setType] = useState("success")
  const [variant, setVariant] = useState(PAGE_VARIANT)
  const [duration, setDuration] = useState(10000)

  const handleClose = () => {
    setIsOpen(false)
    setType("success")
    setVariant(PAGE_VARIANT)
    setDuration(10000)
    setText('')
    AlertCommand.onClose()
  }

  const handleOnOpenCallback = (content) => {
    if (isOpen) {
      handleClose()
    }
    setText(content.text)
    if (content.type) {
      setType(content.type)
    }
    if (content.variant === 'page') {
      setVariant(PAGE_VARIANT)
    }
    if (content.variant === 'inline') {
      setVariant(INLINE_VARIANT)
    }
    if (content.duration) {
      setDuration(content.duration)
    }
    setIsOpen(true)
  }

  const classeVariant = () => {
    return variant === PAGE_VARIANT ? classes.page : null
  }

  AlertCommand.openCallback(handleOnOpenCallback)

  return (
    <Alert
      autoHideDuration={duration}
      alertType={type}
      className={classeVariant()}
      id={elementId('Alert', mfeId)}
      onClose={handleClose}
      open={isOpen}
      text={text}
      variant={variant}
    />
  )
}

AlertMessage.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(AlertMessage)
