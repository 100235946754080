import { permissionContext } from '../../services/stateManagement/localMemory' 

const OVERRIDE = true

export const validateViewPermissions = async (context) =>{
  if (context.path.includes('authentication')) return
  // TEMPORAL: when all pages settled properly, remove warning and uncomment error throwing
  // if (!context.params.viewName) throw new Error(`validateViewPermissions: hook context hasn't received params.viewName`)
  if (!context.params.viewName) {
      console.log("Warning: validateViewPermission: context has not params.viewName")
      return
  }
  const operView = context.params.viewName
  const thePermissionContext = await permissionContext.get('0')
  const perms = permsOfView(operView, thePermissionContext)
  if (operView == 'root' && perms == '') return // to permit to query the permissions from db the very first time, when I do not know the permissions yet!
  const method = context.method
  // console.log(`Validating ${method} with perms ${perms} in view ${operView}`)
  if (!methodPermitted(method, perms)) {
      alert(`You are NOT AUTHORIZED to perform '${method}' operation in this view`)
      throw new Error('not authorized: YOU SHALL NOT PASS')
  }
}

export const permsOfView = (theView, thePermissionContext) => {
  if(OVERRIDE) return 'RWX' // uncomment this line if you want to override permission while developing
  const oal = thePermissionContext.objectAuthorizationList
  if (!oal.length) return 'RWX' // TEMPORAL, remove when all permissions are ready in db
  for (const [view, perms] of oal) if (view === theView ) return perms
  return ''
}

export const methodPermitted = (method, perms) => {
  if (method == 'find' && perms.includes('R')) return true
  if (method == 'get' && perms.includes('R')) return true
  if (method == 'create' && perms.includes('W')) return true
  if (method == 'update' && perms.includes('W')) return true
  if (method == 'patch' && perms.includes('W')) return true
  if (method == 'remove' && perms.includes('W')) return true
  return false
}