import { userContext, permissionContext, sessionProfile } from './localMemory'
import { viewApiClientWrapper } from '../journeyApiClient'
import { journeyUserProperties } from '../api/journeyApiAxiosClient'

const journeyObjectAuthorizationList = viewApiClientWrapper('root', 'object-authorization-list')

const ANONYMOUS = 'anonymous'

// EXPORTS
////////////////////////////////////////////////////////////////////////

export const onSuccessfulLogin = async (auth) => {
  const theUserContext = await userContext.get(0)
  theUserContext.loggedIn = true
  //theUserContext.user = auth.users
  theUserContext.accessToken = auth.accessToken
  
  await userContext.update(0, theUserContext)
}

export const onSuccessfulLogout = async () => {
  // here the user is already gone, so saving the user-properties is handled in the header, just before .logout()
  const theUserContext = await userContext.get(0)
  theUserContext.loggedIn = false
  theUserContext.user = {}
  theUserContext.accessToken = null
  await userContext.update(0, theUserContext)
}

export const onPropertiesSavePoint = async () => {
  // this is called both by the header (on logout) and by a listener in skillMap (on abandon view)
  const theUserContext = await userContext.get(0)
  if (!theUserContext.loggedIn) return
  const theSessionProfile = await sessionProfile.get(0)
  const lastUserProps = theSessionProfile.userProperties
  saveUserProperties(lastUserProps)
}

export const saveUserProperties = (userProperties) => { // REMEMBER: do not use async/wait
  // see note "about user properties persistance" below
  // this function is called both from onPropertiesSavePoint() and from listener in main index
  // when called from main index we want to assure saveProperties is not async (i.e. it does not include promises) 
  // this way the Api is effectevily called (we assume the handler code can finish before the broser tab is killed)
  const propsId = userProperties.id
  const propsUserName = userProperties.userName
  const propsSearches = userProperties.lastCareerPathSearches
  if (!propsUserName) return
  if (!propsId) journeyUserProperties.create({
    userName: propsUserName,
    lastCareerPathSearches: propsSearches
  })
  if (propsId) journeyUserProperties.patch(propsId, {
    lastCareerPathSearches: propsSearches
  })  
}

// RULES
////////////////////////////////////////////////////////////////////////

// Update credentials when user changes:
userContext.on('updated', async (theUserContext) => {
  const thePermissionContext = await permissionContext.get(0)
  let roleList = []
  if (theUserContext.loggedIn === false) {
    roleList = [ANONYMOUS]
    thePermissionContext.role = ANONYMOUS // TEMPORAL; to be removed
  }
  if (theUserContext.loggedIn === true && theUserContext.user?.userName) {
    // const roleList = theUserContext.user.roles // SHOULD BE THIS. TODO when user.role field is removed in db
    roleList = theUserContext.user.roles?.length ? theUserContext.user.roles : [theUserContext.user.role] // TEMPORAL, should be line above
    roleList.unshift(ANONYMOUS)
    thePermissionContext.role = theUserContext.user.role // TEMPORAL; to be removed

    thePermissionContext.roles = roleList

    const data = await journeyObjectAuthorizationList.get(roleList.join(' ')).catch(e => console.log("ATTENTION: stateManagement not able to retreive OAL:", e))
    thePermissionContext.objectAuthorizationList = data?.oal || []
    await permissionContext.update(0, thePermissionContext)
  }
})


// Load user properties into the session profile:
    // Note about user-properties persistance:
    // user properties are loaded/saved any time the user changes. See below and onSuccessfulLogout()
    // besides, user properties are saved any time onPropertiesSavePoint() is called (for example, on leaving the skill map page)
    // but local memory flushes any time you try to close, navigate, or reload the page
    // so, we try to save the properties by calling saveUserProperties() when catching the event 'beforeunload' in the main index.js
    // this event solution is working, but it is not perfect:
    //    The event handler does not wait for promises
    //    It is not reliably fired, especially on mobile platforms (see usage notes at https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event)
    //    It is possible to trigger a confirmation dialog (activate preventDefault()), but it is browser generated
    // the alternative is to save continuosly in the db
    //    to save costs, an option is to use IndexDb API in the browser (save locally), and periodically, copy to Db in backend
userContext.on('updated', async (theUserContext) => {
  const theSessionProfile = await sessionProfile.get(0)
  if (theUserContext.loggedIn === false) {
    // here the user is already gone, so saving the user-properties is handled in the header, just before .logout()
    theSessionProfile.userProperties = {}
  }
  if (theUserContext.loggedIn === true && theUserContext.user?.userName) {
    const {data: data} = await journeyUserProperties.find({query:{userName: theUserContext.user.userName}}) 
    if (data.length > 1) throw new Error(`stateManagement: user ${theUserContext.user.userName} found with multiple user-properties records in db`)
    const userProps = (data.length === 1) ? data[0] : {userName: theUserContext.user.userName} 
    theSessionProfile.userProperties = userProps 
  }
  await sessionProfile.update(0, theSessionProfile)    
})
