import React, { useState } from 'react'
import { withStyles } from '@ellucian/react-design-system/core/styles'
import { spacing30 } from '@ellucian/react-design-system/core/styles/tokens'
import { useIntl } from 'react-intl'
import { componentId, elementId } from '../../utilities/componentHelper'
import { ConfirmationDialog } from '@ellucian/react-design-system/core'
import ConfirmationDialogCommand from '../commands/ConfirmationDialogCommand'

const componentName = 'ConfirmationMessage'
const mfeId = componentId()

const styles = {
  root: {
    marginBottom: spacing30,
  },
}

const ConfirmationMessage = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [title, setTitle] = useState('')
  const [contentText, setContentText] = useState('')
  const [confirmationText, setConfirmationText] = useState('')

  const handleOnCancel = () => {
    ConfirmationDialogCommand.onCancel()
    setIsOpen(false)
  }
  const handleOnConfirm = () => {
    ConfirmationDialogCommand.onConfirm()
    setIsOpen(false)
  }
  const handleOnOpenCallback = (content) => {
    setTitle(content.title)
    setContentText(content.contentText)
    setIsOpen(true)
    if (content?.confirmationText) {
      setConfirmationText(content.confirmationText)
    } else {
      setConfirmationText('')
    }
  }
  ConfirmationDialogCommand.openCallback(handleOnOpenCallback)
  const intl = useIntl()
  const primaryActionText =
    confirmationText === 'Remove'
      ? `${intl.formatMessage({
          id: 'ConfirmationMessage-Remove',
        })}`
      : `${intl.formatMessage({
          id: 'ConfirmationMessage-Confirm',
        })}`
  return (
    <ConfirmationDialog
      contentText={contentText}
      open={isOpen}
      primaryActionOnClick={handleOnConfirm}
      primaryActionText={primaryActionText}
      secondaryActionOnClick={handleOnCancel}
      secondaryActionText={`${intl.formatMessage({
        id: 'ConfirmationMessage-Cancel',
      })}`}
      title={title}
      id={elementId(mfeId, componentName)}
      PaperProps={{
        id: elementId(mfeId, componentName, 'PaperProps'),
      }}
    />
  )
}

export default withStyles(styles)(ConfirmationMessage)
