import React, { useState, useEffect } from 'react'
import {
    Button,
    Grid,
    TextField, 
    Card,
    Typography,
    TextLink
} from '@ellucian/react-design-system/core'
import { journeyApiClient } from '../services/journeyApiClient'
import PropTypes from 'prop-types'
import { withStyles } from '@ellucian/react-design-system/core/styles'
import { useIntl } from 'react-intl'
import { elementId, componentId } from '../utilities/componentHelper'
import AlertCommand from '../components/commands/AlertCommand'
import { journeyUsers } from '../services/journeyApiClient'
import { useNavigate } from 'react-router-dom'
import { tenantContext } from '../services/stateManagement/localMemory'

const styles = () => ({
    loginButtonAlign: {
        marginLeft: '7.5rem'
    },
    createButtonAlign: {
        marginLeft: '3.5rem'
    },
    signUpTextlink: {
      marginLeft: '-1.5rem'
    },
    loginMargin: {
      marginTop: '20px'
    }
})

const mfeId = componentId()
const componentName = "StudentLogin"

const  Login = (props) => {
    const {classes, onSuccess} = props 
    const intl = useIntl()
    const history = useNavigate()

    const [email, setemail] = useState('')
    const [password, setPassword] = useState('')
    const [fullName, setFullName] = useState('')
    const [signUp, setSignUp] = useState(false)
    const [emailHelperText, setEmailHelperText] = useState('')
    const [passwordHelperText, setPasswordHelperText] = useState('')
    const [tenantId, setTenantId] = useState('')

    const handleAuth = async(auth) => {
      await onSuccess(auth)
      if (auth?.users.role === "admin") history('/courses')
      if(auth?.users.role === "student") history('/student-landing')
    }

    const handleOnChangeEmail = (event) => {
        setemail(event.target.value)
    }
    const handleOnChangePassword = (event) => {
        setPassword(event.target.value)
    }

    const handleOnChangefullName = (event) => {
      setFullName(event.target.value)
    }
    const handlePressSignIn = (event) => {
      setSignUp(true)
    }

    const handlePressLogIn = (event) => {
      setSignUp(false)
    }

    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && signUp) {
        handleSignup()
      }
      if (event.key === 'Enter' && !signUp){
        handleLogin()
      }
    }
 
    useEffect(() => {
      const fetchTenantContext = async () =>{
        const theTenantContext = await tenantContext.get(0)
        setTenantId(theTenantContext.tenantId)
      }
      fetchTenantContext();
    },[])

    const handleLogin = async () => {
      if (!email) {
        setEmailHelperText(intl.formatMessage({ id: 'Student-login-Email-helperText-mandatory' }))
      } else {
        setEmailHelperText('')
      }
      if (!password) {
        setPasswordHelperText(intl.formatMessage({id: "Student-login-Password-helperText-mandatory"}))
      }
      else {
        setPasswordHelperText('')
      }
      if (email && password) {
        const tenantUser = tenantId + '_' + email
        try {
          const auth = await journeyApiClient.authenticate({
              strategy: 'local',
              userName: tenantUser.toLowerCase(),
              password
          })
          handleAuth(auth)
        } catch (error) {
          console.error(`${error}`)
          if(error?.message == 'Invalid login') {
            AlertCommand.open({
              text: intl.formatMessage({ id: 'Student-login-invalid-details' }),
              type: 'error'
            })

          } else {
            AlertCommand.open({
              text: intl.formatMessage({ id: 'Student-login-error' }),
              type: 'error'
            })
          }
        }
      }
        
      }
      const handleSignup = async () => {
        if (!email) {
          setEmailHelperText(intl.formatMessage({ id: 'Student-login-Email-helperText-mandatory' }))
        } 
        else {
          setEmailHelperText('')
        }
        if (!password) {
          setPasswordHelperText(intl.formatMessage({id: "Student-login-Password-helperText-mandatory"}))
        }else {
          setPasswordHelperText('')
        }
        if (email && password) {
          if (fullName) {
            await journeyUsers.create({fullName, email: email.toLowerCase(), password,tenantId})
          }
          else {
            await journeyUsers.create({email: email.toLowerCase(), password,tenantId})
          }
            await handleLogin()
        }       
      }

  return (
    <div>
      <Grid container direction = 'column' justifyContent='center' alignItems='center' className={classes.loginMargin}>
        <Card>
          {signUp && 
            <Grid item> 
              <TextField 
                name="fullName"
                label="Full Name" 
                required={false} 
                value={fullName}
                onChange={handleOnChangefullName}>
              </TextField>
            </Grid> 
          }
         
        <Grid item> 
            <TextField 
              name="email" 
              label="email" 
              required={true} 
              value={email}
              onChange={handleOnChangeEmail}
              error={emailHelperText.length > 0}
              helperText={emailHelperText}>
            </TextField>
        </Grid>
        <Grid item>
            <TextField 
              name="password" 
              label="password" 
              required={true} 
              type="password" 
              value={password} 
              onChange={handleOnChangePassword}
              error={passwordHelperText.length > 0}
              helperText={passwordHelperText}
              onKeyDown={handleKeyPress}>  
            </TextField>
        </Grid>
        {!signUp && 
          <Grid item className={classes.loginButtonAlign}> 
            <Button type="submit" onClick={handleLogin}>
              <Typography>{`${intl.formatMessage({id: "Student-login-LogIn-Button" })}`}</Typography>
            </Button>
          </Grid>
        }
        {signUp && 
          <Grid item className={classes.loginButtonAlign}> 
            <Button type="submit" onClick={handleSignup}>
              <Typography>{`${intl.formatMessage({id:"Student-login-SignUp-Button" })}`}</Typography>
            </Button>
          </Grid>
        }
        {!signUp && 
          <Grid item className={classes.createButtonAlign}>
            <Grid container direction="row"> 
              <Grid item>
                <Typography id={elementId(mfeId, componentName, '-details')} variant="body2">
                  {`${intl.formatMessage({
                  id: 'Student-login-create-account',
                  })}`}
                </Typography>
              </Grid>
              <Grid item className={classes.signUpTextlink}>
                <TextLink
                  variant="inherit"
                  id={'SignUP'}
                  onClick={handlePressSignIn}
                >
                  <Typography>{`${intl.formatMessage({
                  id: 'Student-login-signup',
                  })}`}</Typography>
                </TextLink>
              </Grid>
            </Grid>
          </Grid>
        }
        {signUp && 
          <Grid item className={classes.createButtonAlign}>
            <Grid container direction="row"> 
              <Grid item>
                <Typography id={elementId(mfeId, componentName, '-details')} variant="body2">
                  {`${intl.formatMessage({
                  id: 'Student-login-account-already',
                  })}`}
                </Typography>
              </Grid>
              <Grid item className={classes.signUpTextlink}>
                <TextLink
                  variant="inherit"
                  id={'SignUP'}
                  onClick={handlePressLogIn}
                >
                  <Typography>{`${intl.formatMessage({
                  id: 'Student-login-signIn',
                  })}`}</Typography>
                </TextLink>
              </Grid>
            </Grid>
          </Grid>
        }
        </Card>

      </Grid> 
    </div>
  )
}

Login.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string),
    onSuccess: PropTypes.func
  }

export default withStyles(styles)(Login);
