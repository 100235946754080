import axios from 'axios';
import OAuthAuthenticationInterceptor from './interceptors/oauth-authentication.interceptor';

export default class HttpClient {
  static getFormattedURL(url) {
    return url.includes('http://') || url.includes('https://') ? url : `https://${url}`;
  }

  constructor(config, getTokenAsync, options) {
    this.defaultConfiguration = {
      timeout: 45000,
    };

    this.axiosInstance = axios.create({
      ...this.defaultConfiguration,
      ...config,
      baseURL: HttpClient.getFormattedURL(config.baseURL),
    });
    this.initializeInterceptors(getTokenAsync, options);
  }

  initializeInterceptors(getTokenAsync, options) {
    if (getTokenAsync) {
      const oauthAuthenticationInterceptor = OAuthAuthenticationInterceptor.getOrCreate(getTokenAsync);
      this.axiosInstance.interceptors.request.use(oauthAuthenticationInterceptor.authenticateRequest);
      this.axiosInstance.interceptors.response.use(
        response => response,
        oauthAuthenticationInterceptor.onResponseError
      );
    }
  }

  get(url, config) {
    return this.axiosInstance.get(url, config);
  }

  post(url, data, config) {
    return this.axiosInstance.post(url, data, config);
  }

  put(url, data, config) {
    return this.axiosInstance.put(url, data, config);
  }

  patch(url, data, config) {
    return this.axiosInstance.patch(url, data, config);
  }

  delete(url, config) {
    return this.axiosInstance.delete(url, config);
  }
}