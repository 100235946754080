const alertState = {
  open: (content) => {},
  onClose: () => {},
}

const AlertCommand = {
  // Commands for Dialog
  openCallback: (func) => (alertState.open = func),
  onClose: () => alertState.onClose(),

  // Commands for components
  open: (content, onClose) => {
    if (onClose) {
      alertState.onClose = onClose
    }
    alertState.open(content)
  },
}

// export type AlertContent = {
//   text: string
//   type?: AlertType
//   variant?: AlertVariant
//   duration?: number
// }

// export enum AlertType {
//   SUCCESS = 'success',
//   ERROR = 'error',
//   WARNING = 'warning',
//   INFO = 'info',
// }

// export enum AlertVariant {
//   PAGE = 'page',
//   INLINE = 'inline',
// }

export default AlertCommand
