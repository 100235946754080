export const dialogMessageState = {
  open: (content, options) => { },
  onConfirm: (context) => { },
  onCancel: () => { },
  onClose: () => {}, 
  onDisableConfirm: (disabled) => { },
  contentState: null
}

const DialogMessageCommand = {
  // Commands for Dialog
  openCallback: (func) => (dialogMessageState.open = func),
  disableConfirmationCallback: (func) => (dialogMessageState.onDisableConfirm = func),
  onCancel: () => dialogMessageState.onCancel(),
  onConfirm: () => dialogMessageState.onConfirm(dialogMessageState.contentState),
  onClose: () => dialogMessageState.onClose(),

  // Commands for components
  open: (content, options) => {
    if (options?.onCancel) {
      dialogMessageState.onCancel = options.onCancel
    }
    if (options?.onConfirm) {
      dialogMessageState.onConfirm = options.onConfirm
    }
    if(options?.onClose){
      dialogMessageState.onClose = options.onClose
    }else {
      dialogMessageState.onCancel = options?.onCancel
    }
    dialogMessageState.open(content, options)
  },
  disableConfirm: () => {
    dialogMessageState.onDisableConfirm(true)
  },
  enableConfirm: (contentState) => {
    dialogMessageState.onDisableConfirm(false)
    if(contentState) {
      dialogMessageState.contentState = contentState
    }
  },
  getState: () => {
    return dialogMessageState.contentState
  }
}

// export type DialogMessageContent = {
//   title: string
//   children: React.ReactNode
// }

// export type DialogMessageOptions = {
//   onConfirm?: any,
//   onCancel?: any,
//   onClose?: any,
//   buttonConfirmLabel?: string,
//   buttonCancelLabel?: string,
//   childrenClass?: any
// }

export default DialogMessageCommand