import axios from 'axios';

class OAuthAuthenticationInterceptor {
  static getOrCreate(getToken) {
    if (!this.instance) {
      this.instance = new OAuthAuthenticationInterceptor(getToken);
    }

    return this.instance;
  }

  static instance;

  constructor(getToken) {
    this.getTokenAsync = getToken;
    this.accessToken = '';
  }

  async refreshToken() {
    this.accessToken = (await this.getTokenAsync()).access_token;
  }

  authenticateRequest = async (request) => {
    if (!this.accessToken) await this.refreshToken();
    if (request.headers) request.headers.Authorization = `Bearer ${this.accessToken}`;
    return request;
  };

  onResponseError = async (error) => {
    const config = error.config;
    if (
      (error.response?.status === 401 ||
        (error.response?.status === 403 &&
          error.response?.data &&
          error.response?.data['error-type'] === 'ACCESS_DENIED')) &&
      !config._retry
    ) {
      config._retry = true;
      await this.refreshToken();
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${this.accessToken}`,
      };
      return axios(config);
    }
    return Promise.reject(error);
  };
}

export default OAuthAuthenticationInterceptor;