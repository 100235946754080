import HttpClient from '../http-client/HttpClient';
import { userContext } from '../stateManagement/localMemory';

export default class ApiAxiosClientsFactory {
  static journeyApiAxiosInstance;

  static intializeApiClients(getJWT) {
    if (!this.journeyApiAxiosInstance) {
      this.journeyApiAxiosInstance = new HttpClient(
        {
          baseURL: process.env.REACT_APP_JOURNEY_API_URL,
          ...(30000 && { timeout: parseInt("30000", 10) }),
        },
        getJWT
      );
    }
    return true;
  }
  static get JourneyApiAxiosClient() {
    return this.journeyApiAxiosInstance;
  }
}

ApiAxiosClientsFactory.intializeApiClients(async () => {
  const theUserContext = await userContext.get(0)
  return { access_token: theUserContext.accessToken }
});