import { feathers } from '@feathersjs/feathers'
import rest from '@feathersjs/rest-client'
import axios from 'axios'
import socketio from '@feathersjs/socketio-client'
import { io } from 'socket.io-client'
import authentication from '@feathersjs/authentication-client'
import { tenantContext } from '../services/stateManagement/localMemory'
import { validateViewPermissions } from '../services/stateManagement/permissions'
import { initializeJourneyApiAxiosClient} from './api/journeyApiAxiosClient'

const TRANSPORT_TYPE = process.env.REACT_APP_JOURNEY_APICLIENT_TRANSPORT || 'rest'

let tenantId = undefined
let transportClient = null
let restClient = rest(process.env.REACT_APP_JOURNEY_API_URL)
let socketClient = (TRANSPORT_TYPE === 'socketio') ? io(process.env.REACT_APP_JOURNEY_API_URL ?? '', { withCredentials: true }) : null

if (socketClient) {
    socketClient.on("connect_error", () => { console.error(`Error while connecting to ${process.env.REACT_APP_JOURNEY_API_URL} (socket.io)`) })
}
// socketClient.on("disconnect", () => changeServerState('disconnected'));
// socketClient.on("connect", () => changeServerState('connected'));
// socketClient.on("connect_error", () => changeServerState('error'));
// socketClient.on("connect_timeout", () => changeServerState('connect_timeout'));

export const journeyApiClient = feathers()
// export let journeyCategories
// export let journeyCourses
// export let journeySections
// export let journeySkills
// export let journeySkillsMap
export let journeyMessages
export let journeyUsers
// export let journeyUserProperties
// export let journeyStudents
// export let journeyCareerPath
// export let journeyStudentRegistrations
export let journeySkillRecommendations
// export let journeyCoreSkills
export let journeyCourseRecommendations
export let journeySkillRecommendationsAsync
export let journeyQueueManagement
// export let journeyEPS
// export let journeyUserPaymentTransactions
// export let journeyUserActionTrail
export let journeyObjectAuthorizationList

export const initializeJourneyApiClient = async () => {
    initializeJourneyApiAxiosClient()
    try {
        if (transportClient) {
            return
        }
        transportClient = (TRANSPORT_TYPE === 'socketio') ? socketio(socketClient) : restClient.axios(axios)
        journeyApiClient.configure(transportClient)
        const theTenantContext = await tenantContext.get(0)
        tenantId = theTenantContext.tenantId
        journeyServices(tenantId)
        journeyApiClient.configure(authentication())
        journeyApiClient.hooks({
            before: {
                all: [validateViewPermissions],
            }
        })
    } catch (error) {
        console.error('Error initializing journeyApiClient', error)
    }
}

const journeyServices = (tenantId) => {
    // journeyCourses = service(tenantId, 'courses')
    // journeySections = service(tenantId, 'sections')
    // journeyCategories = service(tenantId, 'categories')
    // journeySkills = service(tenantId, 'skills')
    journeyMessages = nontenantService('messages')
    journeyUsers = nontenantService('users')
    //journeyUserProperties = service(tenantId, 'user-properties')
    // journeySkillsMap = service(tenantId, 'skills-map')
    // journeyStudents = service(tenantId, 'students')
    // journeyCareerPath = service(tenantId, 'career-paths')
    // journeyStudentRegistrations = service(tenantId, 'student-registrations')
    journeySkillRecommendations = service(tenantId, 'skill-recommendations')
    journeyCourseRecommendations = service(tenantId, 'course-recommendations')
    journeySkillRecommendationsAsync = service(tenantId, 'skill-recommendations-async')
    // journeyCoreSkills = service(tenantId, 'core-skills')
    journeyQueueManagement = service(tenantId, 'queue-manager')
    // journeyEPS = service(tenantId, 'eps')
    // journeyUserPaymentTransactions = service(tenantId, 'user-payment-transactions')
    // journeyUserActionTrail = service(tenantId, 'user-action-trail')
    journeyObjectAuthorizationList = service(tenantId, 'object-authorization-list')
}

const service = (tenantId, name) => {
    return journeyApiClient.service('api/' + tenantId + "/" + name)
}

const nontenantService = (name) => {
    return journeyApiClient.service(name)
}

export const viewApiClientWrapper = (viewName, serviceName) => ({
    find: (params) => journeyApiClient.service('api/' + tenantId + "/" + serviceName).find({ ...params, viewName }),
    get: (id, params) => journeyApiClient.service('api/' + tenantId + "/" + serviceName).get(id, { ...params, viewName }),
    create: (data, params) => journeyApiClient.service('api/' + tenantId + "/" + serviceName).create(data, { ...params, viewName }),
    update: (id, data, params) => journeyApiClient.service('api/' + tenantId + "/" + serviceName).update(id, data, { ...params, viewName }),
    patch: (id, data, params) => journeyApiClient.service('api/' + tenantId + "/" + serviceName).patch(id, data, { ...params, viewName }),
    remove: (id, params) => journeyApiClient.service('api/' + tenantId + "/" + serviceName).update(id, { ...params, viewName }),
})
