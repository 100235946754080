import React, { useState } from 'react'
import { spacing30 } from '@ellucian/react-design-system/core/styles/tokens'
import DialogMessageCommand from '../commands/DialogMessageCommand'
import { withStyles } from '@ellucian/react-design-system/core/styles'
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Typography } from '@ellucian/react-design-system/core'
import { useIntl } from 'react-intl'
import { componentId, elementId } from '../../utilities/componentHelper'
import PropTypes from 'prop-types'

const styles = () => ({
  root: {
    marginBottom: spacing30,
  },
  defaultContent: {},
  contentNoOverflow: {
    'overflow-y': 'hidden',
    paddingLeft: 'inherit',
    paddingRight: 'inherit',
  },
  paper: {
    'overflow-y': 'visible',
  },
  title: {
    paddingRight: '20px' // Width of the 'X'. Prevent overlapping issues
  }
})

// export enum contentClasses {
//   defaultContent = 'defaultContent',
//   contentNoOverflow = 'contentNoOverflow',
// }

const componentName = 'DialogMessage'
const mfeId = componentId()

const DialogMessage = (props) => {
  // eslint-disable-next-line react/prop-types
  const { classes } = props
  const intl = useIntl()
  const [isOpen, setIsOpen] = useState(false)
  const [title, setTitle] = useState('')
  const [content, setContent] = useState(undefined)
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false)
  const [buttonConfirmLabel, setButtonConfirmLabel] = useState('defaultContent')
  const [contentClass, setContentClass] = useState()

  const handleOnCancel = () => {
    setIsOpen(false)
    DialogMessageCommand.onCancel()
  }

  const handleOnConfirm = () => {
    setIsOpen(false)
    DialogMessageCommand.onConfirm()
  }

  const handleOpen = (content, options) => {
    setTitle(content.title)
    setContent(content.children)
    if (options?.buttonConfirmLabel) {
      setButtonConfirmLabel(options?.buttonConfirmLabel)
    } else {
      setButtonConfirmLabel(
        intl.formatMessage({
          id: 'DialogMessage-Confirm',
        })
      )
    }
    if (options?.childrenClass) {
      setContentClass(options?.childrenClass)
    } else {
      setContentClass('defaultContent')
    }
    setIsOpen(true)
  }

  const handleDisableConfirmation = (disabled) => {
    setIsConfirmDisabled(disabled)
  }

  DialogMessageCommand.openCallback(handleOpen)
  DialogMessageCommand.disableConfirmationCallback(handleDisableConfirmation)

  return (
    <Dialog
      open={isOpen}
      onClose={handleOnCancel}
      id={elementId(mfeId, componentName)}
      PaperProps={{
        id: elementId(mfeId, componentName, 'PaperProps'),
      }}
      maxWidth={false}
      // eslint-disable-next-line react/prop-types
      classes={{ paper: classes.paper }}
    >
      <DialogTitle><div className={
        // eslint-disable-next-line react/prop-types
        classes.title
      }>{title}</div></DialogTitle>
      <DialogContent classes={{ root: classes[contentClass] }}>{content}</DialogContent>
      <DialogActions>
        <Button id={elementId(mfeId, componentName, 'ButtonCancel')} onClick={handleOnCancel} color="secondary">
          <Typography>{`${intl.formatMessage({
            id: 'DialogMessage-Cancel',
          })}`}</Typography>
        </Button>
        <Button
          id={elementId(mfeId, componentName, 'ButtonConfirm')}
          disabled={isConfirmDisabled}
          onClick={handleOnConfirm}
          color="primary"
        >
          <Typography>{buttonConfirmLabel}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogMessage.prototype = {
  classes: PropTypes.object,
}

export default withStyles(styles)(DialogMessage)
