import React from 'react'
import DialogMessage from '../dialogMessage/DialogMessage'
import AlertMessage from '../alertMessage/AlertMessage'
import ConfirmationMessage from '../confirmationMessage/ConfirmationMessage'

const Layout = () => {
  return (
    <>
      <DialogMessage/>
      <AlertMessage/>
      <ConfirmationMessage/>
    </>
  )
}

export default Layout
